<template>
  <div class="container small" v-if="ordre">
    <h1 class="page-headline">Tak for din bestilling!</h1>
    <div class="box">
      <p>Dit ordrenummer er #{{ ordre.orderId }}.</p>
      <p>Vi er meget glade for, at du har valgt Aros Forsikring, og vi vil gøre alt for at leve op til de forventninger,
        du har.</p>
      <p>Du vil snarest modtage en bekræftelsesmail med et forsikringstilbud baseret på de oplysninger, som du har
        indtastet.</p>
      <p>Skulle du have spørgsmål, er du altid meget velkommen til at kontakte os på tlf. <a href="tel:70104222">7010
          4222.</a></p>
    </div>
    <div class="button-flex-container justify-center margin-top-30">
      <a href="https://www.aros-forsikring.dk/" class="button large-padding">Læs mere om Aros Forsikring</a>
    </div>
  </div>
  <div class="container small" v-else-if="henvendelse">
    <h1 class="page-headline">Tak for din henvendelse!</h1>
    <div class="box">
      <p>Vi er glade for din interesse i Aros Forsikring og ser frem til at tale med dig.</p>
      <p :set="dateObject = new Date(henvendelse.date)">Du vil blive kontaktet på det ønskede tidspunkt d.
        {{ dateObject.getDate() }}/{{ dateObject.getMonth() + 1 }} {{ dateObject.getFullYear() }} kl. {{
          dateObject.getHours() }}:{{ dateObject.getMinutes() < 10 ? '0' : '' }}{{ dateObject.getMinutes() }}.</p>
      <p>Skulle du i mellemtiden have spørgsmål, er du altid meget velkommen til at kontakte os på tlf. <a
          href="tel:70104222">7010 4222</a>.</p>
    </div>
    <div class="button-flex-container justify-center margin-top-30">
      <a href="https://www.aros-forsikring.dk/" class="button large-padding">Læs mere om Aros Forsikring</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Ordre",
  props: {
    ordre: {
      type: Object,
      default: null,
    },
    henvendelse: {
      type: Object,
      default: null,
    },
  },
  created() {
    if (!this.ordre && !this.henvendelse) {
      this.$router.push({ name: "Hjem" });
    }
  },
};
</script>
